body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body,div,section,article,nav,p,ul,li,h1,h2,h3,h4,h5,h6 {
  margin: 0;
  padding: 0;
}

html,body,div,section,article,nav {
  font-size: 14px;
}

p, a, h1, h2, h3, h4, h5, h6 {
  color: #595757;
}

.funbusi_subtitle {
  line-height: 2.5em;
  width: 80%;
  margin: 10vh auto 5%;
  border-bottom-width: 4px;
  border-bottom-color: rgb(177, 175, 175);
  border-bottom-style: double;
  position: relative;
}

.funbusi_header {
  margin: 0;
  padding: 0;
}

.funbusi_header .col-md-6 {
  width: 50%;
}

.funbusi_header .col-md-6:last-child {
  position: fixed;
  z-index: 900;
  right: 0;
}

.funbusi_header img {
  display: block;
  position: fixed;
  z-index: 999;
  width: 216px;
  height: 50px;
  margin: 15px 10px;
  animation-name: logo-fade-in;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  -webkit-animation-name: logo-fade-in;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  -o-animation-name: logo-fade-in;
  -o-animation-duration: 1s;
  -o-animation-iteration-count: 1;
  -o-animation-timing-function: ease-in;
  -moz-animation-name: logo-fade-in;
  -moz-animation-duration: 1s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease-in;
  -ms-animation-name: logo-fade-in;
  -ms-animation-duration: 1s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: ease-in;
}

@keyframes logo-fade-in {
  0% {
    -webkit-transform: translateY(7px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}

@-webkit-keyframes logo-fade-in {
  0% {
    -webkit-transform: translateY(7px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}

@-moz-keyframes logo-fade-in {
  0% {
    -webkit-transform: translateY(7px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}

@-o-keyframes logo-fade-in {
  0% {
    -webkit-transform: translateY(7px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}

@-ms-keyframes logo-fade-in {
  0% {
    -webkit-transform: translateY(7px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0px);
    opacity: 1;
  }
}

.funbusi_nav {
  float: right;
  margin: 1vh;
}

.funbusi_nav_btn {
  display: none;
}

.funbusi_nav_btn a {
  color: #fcab2f;
  font-size: 1.3em;
  display: block;
  position: relative;
  font-weight: 600;
}

.funbusi_nav_btn a:hover{
  color: white;
  background-color: #fcab2f;
}

.funbusi_menu_drop {
  display: block;
  margin-top: 1.3vh;
  margin-right: 1vw;
}

.funbusi_dropdown_menu_item {
  text-decoration: none;
}

/* .funbusi_menu_drop a {
  display: block;
  padding-right: ;
} */

.funbusi_para {
  text-align: justify;
  font-size: 1.3em;
  position: relative;
}

.funbusi_banner {
  width: 100%;
  height: 100vh;
  display: block;
  background-image: url('./images/banner.jpg');
  background-position: 50% 50%;
  position: relative;
}

.callbacks {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}

.callbacks li {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}

.banner-text-info {
  margin: 15vh 0 0 8vw;
}

.banner-text-info h1 {
  font-size: 4em;
  word-spacing: 0.3em;
  text-align: left;
  line-height: 1.4em;
  color: #fcab2f;
  width: 70%;
}

#funbusi_slider {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  margin: 0 auto;
}

.about_us_image {
  width: 100%;
  margin: 10px auto 0;
  display: block;
  position: relative;
}

.about_us_section {
  padding-right: 5.5%;
  padding-left: 5.5%;
  margin-bottom: 22vh;
}

.funbusi_card_container {
  margin: 0 auto;
  margin-bottom: 1em;
  position: relative;
}

.funbusi_circle_svg {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 0;
  left: calc(50% - 125px);
  z-index: 900;
  pointer-events: none;
}

.funbusi_circle {
  cx: 125px;
  cy: 125px;
  r: 121px;
  stroke: #fcab2f;
  stroke-width: 4px;
  fill: none;
  animation-name: drawCircle;
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
  transform-origin: center;
  transform: rotate(-90deg);
  -webkit-animation-name: drawCircle;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-transform-origin: center;
  -webkit-transform: rotate(-90deg);
  -o-animation-name: drawCircle;
  -o-animation-duration: 2.5s;
  -o-animation-iteration-count: 1;
  -o-animation-timing-function: ease-in-out;
  -o-transform-origin: center;
  -o-transform: rotate(-90deg);
  -moz-animation-name: drawCircle;
  -moz-animation-duration: 2.5s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease-in-out;
  -moz-transform-origin: center;
  -moz-transform: rotate(-90deg);
  -ms-animation-name: drawCircle;
  -ms-animation-duration: 2.5s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: ease-in-out;
  -ms-transform-origin: center;
  -ms-transform: rotate(-90deg);
}

@keyframes drawCircle{
  0% {
      stroke-dasharray: 0, 10000;
  }
  100% {
      stroke-dasharray: 786, 10000;
  }
}

@-webkit-keyframes drawCircle{
  0% {
      stroke-dasharray: 0, 10000;
  }
  100% {
      stroke-dasharray: 786, 10000;
  }
}

@-o-keyframes drawCircle{
  0% {
      stroke-dasharray: 0, 10000;
  }
  100% {
      stroke-dasharray: 786, 10000;
  }
}

@-ms-keyframes drawCircle{
  0% {
      stroke-dasharray: 0, 10000;
  }
  100% {
      stroke-dasharray: 786, 10000;
  }
}

@-moz-keyframes drawCircle{
  0% {
      stroke-dasharray: 0, 10000;
  }
  100% {
      stroke-dasharray: 786, 10000;
  }
}

.funbusi_card_container p {
  text-align: center;
  width: 100%;
  padding: 28px 35px;
  margin: 0 auto;
  font-size: 1.2em;
}

.funbusi_card {
  margin: 0 auto;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #fffabc;
  border: none;
  position: relative;
}

.funbusi_card h3 {
  margin: auto auto;
  text-align: center;
}

.funbusi_card:hover h3 {
  font-size: 2.2em;
  animation-name: swing;  /*Invoke the animation in animate.css*/
  animation-duration: 0.5s;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  -webkit-animation-name: swing;
  -webkit-animation-duration: 0.5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-name: swing;
  -moz-animation-duration: 0.5s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease-in;
  -o-animation-name: swing;
  -o-animation-duration: 0.5s;
  -o-animation-iteration-count: 1;
  -o-animation-timing-function: ease-in;
  -ms-animation-name: swing;
  -ms-animation-duration: 0.5s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: ease-in;
}

.funbusi_contact {
  width: 87vw;
  margin: 0 auto;
  margin-bottom: 28vh;
}

.funbusi_contact>div {
  position: relative;
}

.submit_btn {
  margin: 0 auto;
  border: 1px #fcab2f solid;
  color: #fcab2f;
  display: block;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  width: 185px;
}

.submit_btn:hover {
  background-color: #fcab2f;
  color: white;
}

.funbusi_linkbar {
  background-color: #fcab2f;
  text-align: center;
}

.funbusi_linkbar p {
  padding: 1em 0 1em 2em;
  line-height: 1.5em;
  font-size: 1em;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.col-md-12 {
  padding-left: 0;
  padding-right: 0;
}

.funbusi_cards_row {
  flex-direction: column;
  text-align: center;
}

.thanks {
  margin-top: 20vh;
}

.thanks img {
  display: block;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.thanks p {
  display: block;
  font-size: 3em;
  margin: 5vh 18vw 0;
  text-align: center;
  color: #fcab2f;
}

@media screen and (min-width: 992px) {

  .funbusi_header img {
    margin: 18px 15px;
  }

  .funbusi_contact {
    width: 56vw;
  }

  .funbusi_nav_btn {
    display: inline;
  }

  .funbusi_menu_drop {
    display: none;
  }

  .funbusi_subtitle {
    line-height: 2.5em;
    width: 36%;
    margin: 10vh auto 5%;
    border-bottom-width: 4px;
    border-bottom-color: rgb(177, 175, 175);
    border-bottom-style: double;
    position: relative;
  }

  .banner-text-info {
    margin: 25vh 0 0 18vw; 
  }

  .banner-text-info h1 {
    font-size: 5em;
    word-spacing: 0.3em;
    text-align: left;
    line-height: 1.4em;
    width: 45%;
  }

  .funbusi_cards_row {
    flex-direction: row;
  }

  .funbusi_card_container p {
    opacity: 0;
    transition: opacity 1s ease-in;
    -webkit-transition: opacity 1s ease-in;
    -o-transition: opacity 1s ease-in;
    -moz-transition: opacity 1s ease-in;
    -ms-transition: opacity 1s ease-in;
  }

  .funbusi_card:hover+p {
    opacity: 1;
  }

  .submit_btn {
    margin: 0 auto;
    border: 1px #fcab2f solid;
    color: #fcab2f;
    display: block;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    width: 185px;
  }
  
  .submit_btn:hover {
    background-color: #fcab2f;
    color: white;
  }

  .funbusi_linkbar p {
    padding: 0.2em 0 0.2em 2em;
    display: inline-block;
    line-height: 3em;
    font-size: 1.3em;
  }
}

@media (min-width: 768px) {
  .funbusi_card_container {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.333333%;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 100%;
  }
}